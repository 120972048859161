.datatable-responsive {
  font-stretch: condensed;
}

.datatable-responsive
  .p-datatable-responsive
  .p-datatable-tbody
  > tr
  > td
  .p-column-title {
  display: none;
}

 .p-column-content-currency {
  text-align: end !important;
}

@media screen and (max-width: 40em) {
  .datatable-responsive
    .p-datatable.p-datatable-responsive
    .p-datatable-thead
    > tr
    > th,
  .datatable-responsive
    .p-datatable.p-datatable-responsive
    .p-datatable-tfoot
    > tr
    > td {
    display: none !important;
  }

  .datatable-responsive
    .p-datatable.p-datatable-responsive
    .p-datatable-tbody
    > tr
    > td {
    text-align: left;
    display: block;
    width: 100%;
    float: left;
    clear: left;
    border: 0 none;
  }

  .datatable-responsive
    .p-datatable.p-datatable-responsive
    .p-datatable-tbody
    > tr
    > td
    .p-column-title {
    padding: 0.4rem;
    min-width: 30%;
    display: inline-block;
    margin: -0.4em 1em -0.4em -0.4rem;
    font-weight: bold;
  }

  .datatable-responsive
    .p-datatable.p-datatable-responsive
    .p-datatable-tbody
    > tr
    > td
    .p-column-content,
  .p-column-content-currency {
    padding: 0;
    min-width: 30%;
    display: inline-block;
    margin: 0;
    text-align: end !important;
  }

  .datatable-responsive
    .p-datatable.p-datatable-responsive
    .p-datatable-tbody
    > tr
    > td:last-child {
    border-bottom: 1px solid var(--surface-d);
  }

  .sm-invisible,
  .treetable-responsive .sm-invisible {
    display: none !important;
  }

  .sm-visible,
  .treetable-responsive .sm-visible {
    display: inline;
    margin-right: 0.5rem;
  }
}

@media only screen and (max-height: 420px) {
  .footer-sm-invisible {
    display: none;
  }
}

.no-padding {
  padding: 0 !important;
}

.treetable-responsive {
  font-stretch: condensed;
}

.sm-visible,
.treetable-responsive .sm-visible {
  display: none;
}

.p-column-filter {
  width: 100%;
}

ion-popover::part(content) {
  left: auto !important;
}

ion-popover::part(arrow) {
  left: auto !important;
}

@media screen and (max-width: 40em) {
  ion-popover::part(content) {
    left: 0 !important;
    width: 100%;
  }
}

@media screen and (min-width: 40em) {
  ion-modal.disclaimer-modal::part(content) {
    --height: 25em;
    --width: 30em;
  }
}

/*.popover-wrapper .popover-content {
    position: relative !important;
    margin: 0 auto !important;
    left: auto !important;
    top: inherit !important;
}*/

/* .p-subdatatable .p-datatable-tbody > tr > td {
    margin-left: 0.3em !important;
} */

.p-subdatatable .p-column-title {
  display: block !important;
  font-size: 0.9em;
  font-family: "primeicons";
}

.readonlyInput {
  --color: rgb(32, 2, 2);
  --background: rgba(214, 205, 205, 0.37);
  --padding-start: 5em;
}
