.container {
    height: 30em;
    position: relative
}

.container .login-card {
    max-width: 30em;
    width: 100%;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
}