.askida-fatura {
    max-width: 42em;
    transition: all .2s ease-in-out;
}

.askida-fatura:hover {
    transform: scale(1.05);
}

.card-hover {
    max-width: 20em;
    transition: all .2s ease-in-out;
}

.home-card-hover {
    max-width: 12em;
    transition: all .2s ease-in-out;
}

.card-hover:hover {
    transform: scale(1.1);
}

.home-card-hover:hover {
    transform: scale(1.1);
}

.disabled-card img {
    opacity: 0.5;
}

.center {
   display:flex;
   justify-content:center;
}

.h1-padding {
    padding: 0.2em;
}

.div-padding {
    padding-bottom: 0.8em;
    font-family: cursive;
}

.ion-col-nospace {
    margin: 0;
    padding: 0;
}

.ion-grid-width {
    max-width: 40em;
}
