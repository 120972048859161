.fill {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden
}
.fill img {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%
}

.image-container {
    position: relative;
    font-family: var(--ion-font-family, inherit);
    font-size: 1em;
    min-width: 100%;
    min-height: 100%
}

.text-block {
    position: absolute;
    top: 1em;
    left: 1em;
    background-color: rgba(0, 0, 0, 0.3);
    color: white;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 10px;
    text-align: start;
}

@media screen and (max-width: 40em) {
    .text-block {
        top: 0.5em;
        left: 0.5em;
        font-size: 0.8em;
        padding-right: 5px;
    }

    .text-block p {
        margin: 0.6em;
    }
}