@media screen and (min-width: 40em) {
    ion-popover.borc-popover::part(content) {
        width: 40em;
        padding: 1em;
    }
}
@media screen and (max-width: 40em) {
    ion-popover.borc-popover::part(content) {
        width: 94%;
    }
}
